// CasaAppFolder.js
import React from "react";
import CasaAppIcon from "../../Resuable/CasaAppIcon/CasaAppIcon";
import { useCasaWindowContext } from "../../../contexts/CasaWindowContext";
import { Typography } from "@mui/material";

const CasaAppFolder = (props) => {
  const { casaWindowState } = useCasaWindowContext();

  const generateCasaAppIcons = () => {
    return props.iconsData.map((icon, index) => (
      <CasaAppIcon
        key={index}
        type="link"
        title={icon.title}
        width={casaWindowState.width}
        color={icon.color}
        altColor={icon.altColor}
        linkto={icon.linkto}
      />
    ));
  };

  const getGridTemplate = () => {
    if (casaWindowState.width > 1400) {
      return { columns: "repeat(6, 1fr)", rows: "repeat(2, 1fr)" }; // 6x2 for wider screens
    } else if (casaWindowState.width > 800) {
      return { columns: "repeat(4, 1fr)", rows: "repeat(3, 1fr)" }; // 4x3 for medium screens
    } else if (casaWindowState.width > 400) {
      return { columns: "repeat(3, 1fr)", rows: "repeat(4, 1fr)" }; // 3x4 for smaller screens
    } else {
      return { columns: "repeat(3, 1fr)", rows: "repeat(4, 1fr)" }; // 3x4 for smaller screens
    }
  };

  const gridTemplate = getGridTemplate();

  return (
    <div>
      <div id="greetingText" style={{ textAlign: "center" }}>
        <Typography
          variant="h4"
          color="white"
          style={{
            display: "flex",
            alignItems: "center",
            textDecoration: "underline",
            fontWeight: "bold",
          }}
        >
          <div style={{ flex: 1, textAlign: "center" }}>{props.title}</div>
        </Typography>
      </div>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: gridTemplate.columns,
          gridTemplateRows: gridTemplate.rows,
          gap: "1.5vh",
          marginTop: "2vh",
          padding: "1rem",
          background: "rgba(100, 100, 100, 0.1)", // Translucent white background
          borderRadius: "15px", // Rounded corners
        }}
      >
        {generateCasaAppIcons()}
      </div>
    </div>
  );
};

export default CasaAppFolder;
