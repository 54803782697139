// Estimating.js
import React from "react";
import CasaPlaceholderScreen from "../../Resuable/CasaPlaceholderScreen/CasaPlaceholderScreen";

const Estimating = (props) => {
  return (
    <CasaPlaceholderScreen name="Estimating" backButton={props.backButton} />
  );
};

export default Estimating;
