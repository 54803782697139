// Settings.js
import React from "react";

import { useUserStateContext } from "../../../contexts/UserStateContext";

import Box from "@mui/material/Box";
import { Button, Paper, Typography, useTheme } from "@mui/material";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import {
  lightModeTheme,
  darkModeTheme,
  texasModeTheme,
  testMagentaTheme,
} from "../../Themes/Themes";
import {
  casaLogo,
  casaLogoColor,
  texasLogo,
  texasLogoColor,
  testLogo,
} from "../../Themes/Logos";
import { useAuthenticator } from "@aws-amplify/ui-react";
import axios from "axios";
import { GetUserAndTokens } from "../../../api/GetUserAndTokens";
import { useAPIContext } from "../../../contexts/APIContext";
import CasaCloseButton from "../../Resuable/CasaCloseButton/CasaCloseButton";

const Settings = (props) => {
  const theme = useTheme();
  const { APIState } = useAPIContext();
  const { userState, updateUserState } = useUserStateContext();
  const { signOut } = useAuthenticator((context) => [context.signOut]);

  async function setCasaSettings(paramToChange, value) {
    let themeObj = paramToChange === "theme" ? value : userState.colorTheme;
    let theme = "";

    if (themeObj === lightModeTheme) {
      theme = "Light";
    } else if (themeObj === darkModeTheme) {
      theme = "Dark";
    } else if (themeObj === texasModeTheme) {
      theme = "Texas";
    } else {
      theme = "Test_Magenta_Theme";
    }

    try {
      const userAuthObj = await GetUserAndTokens();

      const settingsObj =
        paramToChange === "background"
          ? {
              SysFunc: "SetSettings",
              Background: value,
              Theme: theme,
            }
          : {
              SysFunc: "SetSettings",
              Background: userState.bgVideoSrc,
              Theme: theme,
            };

      const response = await axios.get(APIState.SysAPI, {
        params: settingsObj,
        headers: {
          Authorization: userAuthObj.idToken,
        },
      });

      return response.data;
    } catch (e) {}
  }

  const changeColorTheme = (theme) => {
    let logoSettings = { appBarLogo: testLogo, colorLogo: testLogo }; // Default

    if (theme === lightModeTheme || theme === darkModeTheme) {
      logoSettings = { appBarLogo: casaLogo, colorLogo: casaLogoColor };
    } else if (theme === texasModeTheme) {
      logoSettings = { appBarLogo: texasLogo, colorLogo: texasLogoColor };
    }

    updateUserState({ ...logoSettings, colorTheme: theme });
    setCasaSettings("theme", theme);
  };

  const changeBackgroundVideo = (background) => {
    updateUserState({ bgVideoSrc: background });
    setCasaSettings("background", background);
  };

  return (
    <div>
      <Paper
        sx={{
          width: "100%",
          mb: 2,
          position: "relative",
          borderRadius: "8px",
          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
          textAlign: "center",
        }}
      >
        <CasaCloseButton handleClose={props.backButton} />
        <Box
          sx={{
            backgroundColor: theme.palette.customColors.screenHeadingColor, // Update with your desired color
            padding: "10px", // Adjust the padding
            textAlign: "center", // Center-align text
          }}
        >
          <Typography
            variant="h5"
            color="white"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div style={{ flex: 1, textAlign: "center" }}>
              <b>Settings</b>
            </div>
          </Typography>
        </Box>
        <Box
          sx={{
            paddingRight: "2em",
            paddingLeft: "2em",
            paddingBottom: "1em",
            paddingTop: "0.5em",
            overflow: "auto",
          }}
        >
          <center>
            <br />
            <img
              src={require("../../../../src/images/default_avi.png")}
              alt={props.firstname}
              width="100px"
            />
            <br />
            <span
              style={{
                fontSize: "1.5em",
                fontWeight: "bold",
                display: "block",
              }}
            >
              {userState.name}
            </span>
            <span style={{ fontSize: "1em" }}>{userState.departmentTitle}</span>
            <br />
            <br />
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="bgVideoSrc-select">Background</InputLabel>
              <Select
                labelId="bgVideoSrc-select"
                id="bgVideoSrc-select"
                value={userState.bgVideoSrc}
                label="Background"
                onChange={(event) => changeBackgroundVideo(event.target.value)}
                autoWidth={true}
              >
                <MenuItem value={"Casa"}>Casa</MenuItem>
                <MenuItem value={"Longhorns"}>Longhorns</MenuItem>
                <MenuItem value={"Fireplace"}>Fireplace</MenuItem>
                <MenuItem value={"Christmas"}>Christmas</MenuItem>
                <MenuItem value={"None"}>None</MenuItem>
              </Select>
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="bgVideoSrc-select">Theme</InputLabel>
              <Select
                labelId="bgVideoSrc-select"
                id="bgVideoSrc-select"
                value={userState.colorTheme}
                label="Background"
                onChange={(event) => changeColorTheme(event.target.value)}
                autoWidth={true}
              >
                <MenuItem value={lightModeTheme}>Light</MenuItem>
                <MenuItem value={darkModeTheme}>Dark</MenuItem>
                <MenuItem value={texasModeTheme}>Texas</MenuItem>
                <MenuItem value={testMagentaTheme}>Test_Magenta_Theme</MenuItem>
              </Select>
            </FormControl>
            <br />
            <br />
            <br />
            <Button
              variant="contained"
              color="primary"
              style={{
                marginTop: "-10px",
              }}
              onClick={signOut}
            >
              Sign Out
            </Button>
            {/* <span><Switch size="large" checked={userState.darkMode} onClick={toggleDarkMode} /> Dark Mode</span> */}
          </center>
          <br />
        </Box>
      </Paper>
    </div>
  );
};

export default Settings;
