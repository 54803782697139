import { createContext, useContext, useEffect, useState } from "react";

const CasaWindowContext = createContext();

export const useCasaWindowContext = () => {
  const context = useContext(CasaWindowContext);
  if (!context) {
    throw new Error(
      "useCasaWindowContext must be used within a CasaWindowProvider"
    );
  }
  return context;
};

export const CasaWindowProvider = ({ children }) => {
  const [casaWindowState, setCasaWindowState] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setCasaWindowState({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    // Call handleResize initially to set the state
    handleResize();

    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const updateCasaWindow = (newState) => {
    setCasaWindowState((prevState) => ({ ...prevState, ...newState }));
  };

  return (
    <CasaWindowContext.Provider value={{ casaWindowState, updateCasaWindow }}>
      {children}
    </CasaWindowContext.Provider>
  );
};
