const texasLogo = 
    <svg xmlns="http://www.w3.org/2000/svg" 
    height="168.98" viewBox="0 0 331.62189 168.97772" width="331.62" version="1.1">
        <g transform="matrix(1.25 0 0 -1.25 -390.11 305.09)">
            <g fill="#bf5700" transform="translate(574.03 241.88)">
                <path d="m0 0c-9.484 2.578-20.969 0.665-30.204-2.164-17.64-5.659-32.699-17.641-49.091-26.211-8.655-3.661-20.555-4.41-28.542 0.583-1.913 1.358-5.38 0.388-5.38 0.388-1.109 0.831-2.352 1.148-3.856 0.611-3.994-2.914-8.155 1.58-12.396-0.75-4.242 2.33-8.402-2.164-12.396 0.75-1.505 0.537-2.747 0.22-3.856-0.611 0 0-3.469 0.97-5.382-0.388-7.986-4.993-19.885-4.244-28.539-0.583-16.394 8.57-31.452 20.552-49.092 26.211-9.236 2.829-20.72 4.742-30.205 2.164-1.248-0.832-2.664-2.331-2.331-4.161 0.666-1.082 1.333-2.58 2.747-2.83 33.95 5.076 52.254-28.623 80.712-37.526 0.055-0.361-1.609-1.747-3.413-2.718-4.021-2.22-9.985-1.359-10.4-6.851 1.081-3.413 5.241-4.661 8.237-5.991 7.073-3.997 15.562-0.668 21.967 2.578 0.748 0.75 1.997 0.334 2.578-0.416-0.499-15.395 11.234-25.63 13.397-39.941 1.249-6.657-2.912-11.564-3.411-17.806-0.055-2.303 1.747-0.778 2.718-8.404 0.417-1.387 3.884-5.271 7.018-6.906 2.893-1.14 6.246-1.472 9.651-1.383 3.404-0.089 6.757 0.243 9.65 1.383 3.134 1.635 6.602 5.519 7.015 6.906 0.974 7.626 2.776 6.101 2.721 8.404-0.5 6.242-4.66 11.149-3.412 17.806 2.164 14.311 13.896 24.546 13.396 39.941 0.584 0.75 1.833 1.166 2.581 0.416 6.405-3.246 14.892-6.575 21.967-2.578 2.995 1.33 7.155 2.578 8.237 5.991-0.417 5.492-6.381 4.631-10.4 6.851-1.805 0.971-3.469 2.357-3.415 2.718 28.459 8.903 46.765 42.602 80.714 37.526 1.414 0.25 2.08 1.748 2.744 2.83 0.323 1.828-1.09 3.327-2.338 4.159" fill="#ffffff"/>
            </g>
        </g>
    </svg>


const texasLogoColor =
<svg  version="1.1" baseProfile="basic" id="Layer_1" width="73.5pt" height="110.207"
viewBox="0 0 89.983 110.207" overflow="visible" enable-background="new 0 0 89.983 110.207" >
<g>
<path fill="#BF5700" d="M38.728,14.662h-6.726V23.6h25.468v-8.938h-6.693l0.006-14.481h26.959v14.481H71.39v8.925h18.594v21.269
   H76.277v-6.394h-4.75c0,14.556,1.031,25.712-7.714,34.457c-3.379,3.379-7.236,5.642-11.947,6.885l-0.001,10.71l5.614-0.016v13.626
   H31.997l-0.026-13.6h5.44V79.805c-6.62-2.136-15.13-9.148-17.202-17.499c-2.135-7.108-1.466-16.002-1.593-23.843h-4.92v6.379H0
   V23.601h18.392l-0.048-8.965l-6.446,0.016V0h26.831V14.662z"/>
<path fill="#FFFFFF" d="M37.416,65.025c-2.556-1.562-5.419-4.888-5.419-9.031l-0.011-17.5h5.43V65.025z"/>
<path fill="#FFFFFF" d="M57.577,56.09c0.021,3.793-1.286,7.937-5.723,9.339V38.494h5.723V56.09z"/>
</g>
</svg>



const casaLogo =
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="73.5pt" height="73.5pt" viewBox="0 0 147.000000 147.000000" preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,147.000000) scale(0.100000,-0.100000)" fill="white" stroke="white">
            <path d="M 515 1292 l -210 -157 l 1 68 l 1 67 l -56 0 l -58 1 l 0 -119 l 0 -119 l -60 -43 l -59 -42 l 0 -54 l -2 -51 c 6 0 157 111 336 246 c 180 135 332 248 338 250 c 7 3 61 -33 121 -79 l 569 -419 l 6 52 c 0 36 13 36 -22 67 c -13 11 -135 105 -273 209 c -137 103 -281 212 -319 241 c -38 29 -72 53 -75 53 z"/>
            <path d="M 162 1330 l 0 -30 l 85 0 l 85 0 l 0 30 l 0 30 l -85 0 l -85 0 l 0 -30 z"/>
            <path d="M545 1114 c-50 -36 -94 -72 -98 -80 -4 -7 -6 -208 -5 -446 l3 -433 80 0 80 0 2 315 c1 173 6 316 11 318 5 1 19 -55 31 -125 13 -71 27 -150 33 -176 l10 -48 51 3 51 3 27 135 c 14 74 30 152 33 173 4 20 11 37 16 37 6 0 10 -123 10 -320 l0 -320 80 0 80 0 0 444 0 444 -96 72 c -53 39 -98 69 -101 67 -6 -6 -46 -202 -68 -337 -16 -96 -28 -131 -39 -119 -2 2 -23 106 -47 232 -24 125 -46 227 -49 227 -3 0 -46 -30 -95 -66z"/>
            <path d="M1215 841 c-51 -13 -72 -29 -94 -74 -23 -48 -29 -146 -10 -194 5 -15 44 -63 86 -105 73 -73 76 -79 81 -130 3 -41 0 -57 -12 -67 -29 -24 -46 -6 -46 49 l0 50 -61 0 -62 0 5 -70 c8 -109 50 -154 142 -154 98 0 150 71 150 203 0 97 -15 127 -99 211 -69 69 -75 78 -75 116 0 50 20 73 44 49 9 -9 16 -32 16 -51 l0 -34 55 0 c66 0 71 7 55 84 -9 42 -19 62 -46 85 -19 17 -43 31 -54 31 -10 0 -24 2 -32 4 -7 2 -26 1 -43 -3z"/>
            <path d="M150 822 c-19 -9 -43 -33 -55 -52 -19 -33 -20 -52 -20 -275 0 -267 4 -286 68 -325 41 -25 120 -26 167 -2 51 26 71 69 78 167 l5 85 -62 0 -61 0 0 -66 c0 -72 -12 -104 -38 -104 -39 0 -42 16 -42 245 0 213 1 223 21 241 21 19 21 18 40 -5 13 -16 19 -39 19 -77 l0 -54 61 0 62 0 -6 75 c-6 86 -27 128 -73 150 -43 20 -121 19 -164 -3z"/>
            <path d="M 62 36 l 670 0 l 670 0 l 0 40 l 0 40 l -668 3 l -672 0 z"/>
        </g>
    </svg>

const casaLogoColor =
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="73.5pt" height="73.5pt" viewBox="0 0 147.000000 147.000000" preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,147.000000) scale(0.100000,-0.100000)" fill="black" stroke="white">
            <path d="M 515 1292 l -210 -157 l 1 68 l 1 67 l -56 0 l -58 1 l 0 -119 l 0 -119 l -60 -43 l -59 -42 l 0 -54 l -2 -51 c 6 0 157 111 336 246 c 180 135 332 248 338 250 c 7 3 61 -33 121 -79 l 569 -419 l 6 52 c 0 36 13 36 -22 67 c -13 11 -135 105 -273 209 c -137 103 -281 212 -319 241 c -38 29 -72 53 -75 53 z"/>
            <path d="M 162 1330 l 0 -30 l 85 0 l 85 0 l 0 30 l 0 30 l -85 0 l -85 0 l 0 -30 z"/>
        </g>
        <g transform="translate(0.000000,147.000000) scale(0.100000,-0.100000)" fill="#0e570b" stroke="white">
            <path d="M545 1114 c-50 -36 -94 -72 -98 -80 -4 -7 -6 -208 -5 -446 l3 -433 80 0 80 0 2 315 c1 173 6 316 11 318 5 1 19 -55 31 -125 13 -71 27 -150 33 -176 l10 -48 51 3 51 3 27 135 c 14 74 30 152 33 173 4 20 11 37 16 37 6 0 10 -123 10 -320 l0 -320 80 0 80 0 0 444 0 444 -96 72 c -53 39 -98 69 -101 67 -6 -6 -46 -202 -68 -337 -16 -96 -28 -131 -39 -119 -2 2 -23 106 -47 232 -24 125 -46 227 -49 227 -3 0 -46 -30 -95 -66z"/>
            <path d="M1215 841 c-51 -13 -72 -29 -94 -74 -23 -48 -29 -146 -10 -194 5 -15 44 -63 86 -105 73 -73 76 -79 81 -130 3 -41 0 -57 -12 -67 -29 -24 -46 -6 -46 49 l0 50 -61 0 -62 0 5 -70 c8 -109 50 -154 142 -154 98 0 150 71 150 203 0 97 -15 127 -99 211 -69 69 -75 78 -75 116 0 50 20 73 44 49 9 -9 16 -32 16 -51 l0 -34 55 0 c66 0 71 7 55 84 -9 42 -19 62 -46 85 -19 17 -43 31 -54 31 -10 0 -24 2 -32 4 -7 2 -26 1 -43 -3z"/>
            <path d="M150 822 c-19 -9 -43 -33 -55 -52 -19 -33 -20 -52 -20 -275 0 -267 4 -286 68 -325 41 -25 120 -26 167 -2 51 26 71 69 78 167 l5 85 -62 0 -61 0 0 -66 c0 -72 -12 -104 -38 -104 -39 0 -42 16 -42 245 0 213 1 223 21 241 21 19 21 18 40 -5 13 -16 19 -39 19 -77 l0 -54 61 0 62 0 -6 75 c-6 86 -27 128 -73 150 -43 20 -121 19 -164 -3z"/>
        </g>
        <g transform="translate(0.000000,147.000000) scale(0.100000,-0.100000)" fill="black" stroke="white">
        <path d="M 62 36 l 670 0 l 670 0 l 0 40 l 0 40 l -668 3 l -672 0 z"/>
        </g>
    </svg>

const testLogo =
<svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="73.5pt" height="73.5pt" viewBox="0 0 147.000000 147.000000" preserveAspectRatio="xMidYMid meet">
        <rect width="100" height="100" x="50" y="50" fill="black" />
    </svg>

export { casaLogo, casaLogoColor, texasLogo, texasLogoColor, testLogo };
