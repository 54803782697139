// Purchasing.js
import React from "react";
import CasaPlaceholderScreen from "../../Resuable/CasaPlaceholderScreen/CasaPlaceholderScreen";

const Purchasing = (props) => {
  return (
    <CasaPlaceholderScreen name="Purchasing" backButton={props.backButton} />
  );
};

export default Purchasing;
