import React from "react";
import { IconButton, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
const CasaCloseButton = (props) => {
  const theme = useTheme();
  return (
    <IconButton
      sx={{
        position: "absolute",
        color: "#fff",
        border: "1px solid #fff",
        left: "-1.25rem",
        top: "-1rem",
        backgroundColor: theme.palette.customColors.secondaryCircleButtonColor,
        "&:hover": {
          backgroundColor:
            theme.palette.customColors.secondaryCircleButtonColorHover,
        },
      }}
      onClick={props.handleClose}
    >
      <CloseIcon />
    </IconButton>
  );
};

export default CasaCloseButton;
