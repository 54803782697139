// MainContent.js
import React from "react";

import { InventoryProvider } from "../InventoryContext";
import { AdministrationProvider } from "../AdministrationContext";
import { CasaWindowProvider } from "../CasaWindowContext";
import { APIProvider } from "../APIContext";

const AllProviders = ({ children, ...props }) => {
  const childrenWithProps = React.Children.map(children, (child) => {
    // Pass the props to each child
    return React.cloneElement(child, { ...props });
  });

  return (
    <APIProvider>
      <CasaWindowProvider>
        <AdministrationProvider>
          <InventoryProvider>{childrenWithProps}</InventoryProvider>
        </AdministrationProvider>
      </CasaWindowProvider>
    </APIProvider>
  );
};

export default AllProviders;
