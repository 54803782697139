// JobRecords.js
import React from "react";
import CasaPlaceholderScreen from "../../Resuable/CasaPlaceholderScreen/CasaPlaceholderScreen";

const JobRecords = (props) => {
  return (
    <CasaPlaceholderScreen name="Job Records" backButton={props.backButton} />
  );
};

export default JobRecords;
