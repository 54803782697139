import { createContext, useContext, useState } from "react";
import { lightModeTheme } from "../components/Themes/Themes";
import { casaLogo, casaLogoColor } from "../components/Themes/Logos";

const UserStateContext = createContext();

export const useUserStateContext = () => {
  const context = useContext(UserStateContext);
  if (!context) {
    throw new Error(
      "useUserStateContext must be used within a UserStateProvider"
    );
  }
  return context;
};

export const UserStateProvider = ({ children }) => {
  const [userState, setUserState] = useState({
    hideTimeClock: true,
    hideDepartmentSelector: true,
    colorTheme: lightModeTheme,
    appBarLogo: casaLogo,
    colorLogo: casaLogoColor,
    bgVideoSrc: "Casa",
    audioEnabled: false,
    name: "-",
    departmentTitle: "-",
    department: "-",
    email: "",
    clockedIn: false,
    getDepartmentIndex: (dept) => {
      if (dept === "Air Conditioning") {
        return 0;
      } else if (dept === "Plumbing") {
        return 1;
      } else if (dept === "Service") {
        return 2;
      } else {
        // default to plumbing
        return 1;
      }
    },
    // Add more settings as needed
  });

  const updateUserState = (newUserState) => {
    setUserState((prevUserState) => ({ ...prevUserState, ...newUserState }));
  };

  return (
    <UserStateContext.Provider value={{ userState, updateUserState }}>
      {children}
    </UserStateContext.Provider>
  );
};
