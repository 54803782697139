// CasaIdleTableView.js
import React from "react";
import { Box, CircularProgress, Paper } from "@mui/material";

const CasaIdleTableView = (props) => {
  if (props.isLoading) {
    return (
      <div sx={{ minHeight: "60vh", maxHeight: "60vh" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center", // Centers horizontally
            alignItems: "center", // Centers vertically
            height: "50vh", // Ensure the div takes up full height of its container
            textAlign: "center", // Centers text inside the div
            padding: "100px",
          }}
        >
          <CircularProgress size={60} />
        </div>
      </div>
    );
  } else {
    return (
      <div sx={{ minHeight: "60vh", maxHeight: "60vh" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center", // Centers horizontally
            alignItems: "center", // Centers vertically
            height: "50vh", // Ensure the div takes up full height of its container
            textAlign: "center", // Centers text inside the div
            padding: "100px",
          }}
        >
          <p>{props.noResultText}</p>
        </div>
      </div>
    );
  }
};

export default CasaIdleTableView;
