import { createContext, useContext, useState } from "react";

const APIContext = createContext();

export const useAPIContext = () => {
  const context = useContext(APIContext);
  if (!context) {
    throw new Error("useAPIContext must be used within an APIProvider");
  }
  return context;
};

export const APIProvider = ({ children }) => {
  const [APIState] = useState({
    SysAPI: "https://6lgfz0ts1f.execute-api.us-east-2.amazonaws.com/sys/sysapi",
    InvAPI: "https://plbopd3ahk.execute-api.us-east-2.amazonaws.com/inv/invapi",
  });

  return (
    <APIContext.Provider value={{ APIState }}>{children}</APIContext.Provider>
  );
};
