// Scheduling.js
import React from "react";
import CasaPlaceholderScreen from "../../Resuable/CasaPlaceholderScreen/CasaPlaceholderScreen";

const Scheduling = (props) => {
  return (
    <CasaPlaceholderScreen name="Scheduling" backButton={props.backButton} />
  );
};

export default Scheduling;
