import { fetchAuthSession, getCurrentUser } from "aws-amplify/auth";

export async function GetUserAndTokens() {
  try {
    const userData = await getCurrentUser();
    if (!userData) {
      console.error("Failed to retrieve Cognito user.");
      throw new Error("CasaException: Failed to retrieve Cognito user.");
    }
    const session = await fetchAuthSession();
    const accessTokenData = session.tokens.accessToken;
    const idTokenData = session.tokens.idToken;

    if (!accessTokenData || !idTokenData) {
      console.error("Failed to retrieve user tokens.");
      throw new Error("CasaException: Failed to retrieve user tokens.");
    }
    return {
      user: userData,
      accessToken: accessTokenData,
      idToken: idTokenData,
    };
  } catch (e) {
    console.error("Timeclock API error.");
    return [];
  }
}
