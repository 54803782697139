// BulkCount.js
import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { visuallyHidden } from "@mui/utils";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import { useTheme, styled } from "@mui/material/styles";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import SettingsIcon from "@mui/icons-material/Settings";

import {
  FormControl,
  Grid,
  InputLabel,
  Select,
  TextField,
  useMediaQuery,
} from "@mui/material";
import CasaSnackbar from "../../Resuable/CasaSnackbar/CasaSnackbar";
import "./Inventory.css"; // Import CSS for transitions
import { useState } from "react";
import CasaCloseButton from "../../Resuable/CasaCloseButton/CasaCloseButton";

function createData(
  id,
  category,
  group,
  description,
  mfgNum,
  type,
  stage,
  count,
  printBool,
  unit,
  cost,
  qty,
  vendor,
  notes
) {
  return {
    id,
    category,
    group,
    description,
    mfgNum,
    type,
    stage,
    count,
    printBool,
    unit,
    cost,
    qty,
    vendor,
    notes,
  };
}

const filters = [
  {
    value: "flexduct",
    label: "Flex Duct",
  },
  {
    value: "grills",
    label: "Grills",
  },
  {
    value: "gemaire",
    label: "Gemaire",
  },
  {
    value: "robertmaddensouth",
    label: "Robert Madden South",
  },
  {
    value: "registerboxes",
    label: "Register Boxes",
  },
  {
    value: "startcollars",
    label: "Start Collars",
  },
];

const initRows = [
  createData(
    1,
    "Lennox",
    "Heat Pumps",
    "Lennox 2.0-2.5-ton Heat Pump SEER 3",
    "LP5DNXC30L00",
    "Equipment",
    "Stage 1",
    10,
    true,
    "Each",
    1200,
    25,
    "ABC Supplier",
    "Some notes 1"
  ),
  createData(
    2,
    "Trane",
    "Furnaces",
    "Trane 80,000 BTU Gas Furnace",
    "TU80DNVXH00",
    "Material",
    "Stage 2",
    15,
    true,
    "Each",
    1500,
    20,
    "XYZ Supplier",
    "Some notes 2"
  ),
  createData(
    3,
    "Goodman",
    "Air Conditioners",
    "Goodman 3.5-ton Air Conditioner SEER 4",
    "GA35DNXAC42L00",
    "Product",
    "Stage 3",
    20,
    true,
    "Each",
    1800,
    30,
    "LMN Supplier",
    "Some notes 3"
  ),
  createData(
    4,
    "York",
    "Air Handlers",
    "York 1.5-ton Multi-Pos Fan Coil SEER 2",
    "YJ15DNXB18L00",
    "Component",
    "Stage 4",
    25,
    true,
    "Each",
    2000,
    35,
    "PQR Supplier",
    "Some notes 4"
  ),
  createData(
    5,
    "Rheem",
    "Heat Pumps",
    "Rheem 2.5-3.0-ton Heat Pump SEER 5",
    "RP25DNXC36L00",
    "Accessory",
    "Stage 5",
    30,
    true,
    "Each",
    2200,
    40,
    "STU Supplier",
    "Some notes 5"
  ),
  createData(
    6,
    "Carrier",
    "Air Handlers",
    "Carrier 2.5-3.0-ton Multi-Pos Fan Coil SEER 4",
    "CJ25DNXB36L00",
    "Material",
    "Stage 6",
    35,
    true,
    "Each",
    2400,
    45,
    "VWX Supplier",
    "Some notes 6"
  ),
  createData(
    7,
    "Trane",
    "Furnaces",
    "Trane 100,000 BTU Gas Furnace",
    "TU100DNVXH00",
    "Product",
    "Stage 7",
    40,
    true,
    "Each",
    2600,
    50,
    "YZA Supplier",
    "Some notes 7"
  ),
  createData(
    8,
    "Lennox",
    "Air Conditioners",
    "Lennox 4.0-ton Air Conditioner SEER 5",
    "LA40DNXAC48L00",
    "Equipment",
    "Stage 8",
    45,
    true,
    "Each",
    2800,
    55,
    "ABC Supplier",
    "Some notes 8"
  ),
  createData(
    9,
    "Goodman",
    "Heat Pumps",
    "Goodman 3.0-3.5-ton Heat Pump SEER 4",
    "GH35DNXC36L00",
    "Component",
    "Stage 9",
    50,
    true,
    "Each",
    3000,
    60,
    "LMN Supplier",
    "Some notes 9"
  ),
  createData(
    10,
    "York",
    "Furnaces",
    "York 90,000 BTU Gas Furnace",
    "YU90DNVXH00",
    "Accessory",
    "Stage 10",
    55,
    true,
    "Each",
    3200,
    65,
    "PQR Supplier",
    "Some notes 10"
  ),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, countType, countLabel } = props;
  //   const createSortHandler = (property) => (event) => {
  //     onRequestSort(event, property);
  //   };

  const [headCells] = useState([
    {
      id: "description",
      numeric: false,
      disablePadding: false,
      label: "Description",
    },
    {
      id: countType,
      numeric: false,
      disablePadding: false,
      label: countLabel,
    },
  ]);

  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{ color: "#fff", textAlign: "center" }}>
          <span>-</span>
        </TableCell>

        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            // sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              "&:hover": {
                color: "#fff", // Change this to the desired hover color
              },
              "&:focus": {
                color: "#fff",
                fontWeight: "bold",
              },
            }}
          >
            <TableSortLabel
              //   active={orderBy === headCell.id}
              //   direction={orderBy === headCell.id ? order : "asc"}
              //   onClick={createSortHandler(headCell.id)}
              sx={{
                "&.MuiTableSortLabel-root": {
                  color: "white",
                },

                "&.Mui-active": {
                  color: "white",
                  textDecoration: "underline",
                  fontWeight: "bold",
                },
                "& .MuiTableSortLabel-icon": {
                  color: "white !important",
                  opacity: "0 !important",
                },
              }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function ThickInventoryHeader({
  name,
  title,
  rows,
  setRows,
  handleAddMode,
  setFilteredRows,
  setPage,
  backButton,
}) {
  const [searchTerm, setSearchTerm] = React.useState("");
  const [selectedColumn, setSelectedColumn] = React.useState("flexduct");

  // == FIX THIS ==
  // this needs to refresh the rows based on filter search

  React.useEffect(() => {
    const filteredRows = rows.filter((row) => {
      const value = row["description"].toString().toLowerCase();
      return value.includes(searchTerm.toLowerCase());
    });
    setPage(0);
    setFilteredRows(filteredRows);
  }, [searchTerm, selectedColumn, rows, setFilteredRows, setPage]);

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md")); // Determine screen size

  const handleSearchFilterChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleColumnFilterChange = (event) => {
    setSelectedColumn(event.target.value);
  };

  return (
    <Paper sx={{ width: "100%", mb: 2, position: "relative", padding: "2px" }}>
      {/* Add button for inventory - commented out b/c moving to dedicated app icon */}
      <CasaCloseButton handleClose={backButton} />
      <IconButton
        sx={{
          position: "absolute",
          top: -16,
          right: -16,
          zIndex: 1,
          backgroundColor: theme.palette.customColors.circleButtonColor, // Background color of the dot
          color: "#fff", // Icon color
          "&:hover": {
            backgroundColor: theme.palette.customColors.circleButtonColorHover, // Background color on hover
          },
        }}
        aria-label="add"
        onClick={handleAddMode}
      >
        <SettingsIcon />
      </IconButton>
      <div
        style={{
          textAlign: isLargeScreen ? "left" : "center", // Align content based on screen size
          display: isLargeScreen ? "flex" : "block", // Use flexbox for alignment
          alignItems: "center", // Align items vertically
          justifyContent: isLargeScreen ? "space-between" : "center", // Adjust justify content based on screen size
          padding: "1vh 1vw", // Uniform padding
        }}
      >
        <Typography
          sx={{
            flex: "1 1 100%",
            textDecoration: "underline",
            padding: "1vh",
          }}
          variant="h5"
          id="tableTitle"
          component="div"
        >
          <b>{title}</b>
        </Typography>
        {/* {isLargeScreen && ( // Render filter and search field only on large screens */}
        <>
          <TextField
            id="select-filter-dropdown"
            select
            label="Group"
            value={selectedColumn}
            onChange={handleColumnFilterChange}
            sx={{
              minWidth: "10em",
              marginRight: "8px", // Added margin between filters and search field
            }}
          >
            {filters.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            id="search-filter-textfield"
            label="Search"
            value={searchTerm}
            onChange={handleSearchFilterChange}
            sx={{
              minWidth: "15em",
              paddingTop: "0.5vh",
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            variant="standard"
          />
        </>
        {isLargeScreen ? (
          <>
            <br />
            <br />
          </>
        ) : (
          <></>
        )}
        {/* )} */}
      </div>
    </Paper>
  );
}

export default function BulkCount(props) {
  const theme = useTheme();

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("description");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rows, setRows] = React.useState(initRows);
  const [editRow, setEditRow] = React.useState(null);
  const [addMode, setAddMode] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const [filteredRows, setFilteredRows] = React.useState(initRows);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [countType, setCountType] = React.useState("countQuantity");
  const [countLabel, setCountLabel] = React.useState("Count Qty");

  function handleEditRow(data) {
    setEditMode(false);
    if (data) setEditRow(data);
  }

  function handleEditMode() {
    setEditMode(!editMode);
    setAddMode(false);
  }

  function handleAddMode() {
    setAddMode(!addMode);
    setEditMode(false);
  }

  function handleSave() {
    // add POST api call here later to CasaDB
    const updatedRows = [...rows];
    const description = document.getElementById("description-textfield").value;
    const category = document.getElementById("category-textfield").value;
    const mfgNum = document.getElementById("mfg-num-textfield").value;
    const group = document.getElementById("group-textfield").value;
    const type = document.getElementById("type-textfield").value;
    const stage = document.getElementById("stage-textfield").value;
    const count = document.getElementById("count-textfield").value;
    const unit = document.getElementById("unit-textfield").value;
    const cost = document.getElementById("cost-textfield").value;
    const qty = document.getElementById("qty-textfield").value;
    const vendor = document.getElementById("vendor-textfield").value;
    const notes = document.getElementById("notes-textfield").value;

    editRow.description = description;
    editRow.category = category;
    editRow.mfgNum = mfgNum;
    editRow.group = group;
    editRow.type = type;
    editRow.stage = stage;
    editRow.count = count;
    editRow.unit = unit;
    editRow.cost = cost;
    editRow.qty = qty;
    editRow.vendor = vendor;
    editRow.notes = notes;

    updatedRows[editRow.id - 1] = editRow;
    setRows(updatedRows);
    setSnackbarOpen(true);
    handleEditRow(null);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const visibleRows = React.useMemo(
    () =>
      stableSort(filteredRows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [filteredRows, order, orderBy, page, rowsPerPage]
  );

  function handleClose() {
    setAddMode(false);
    setEditMode(false);
    setEditRow(null);
  }

  function handleCountTypeChange(value) {
    setCountType(value);
    if (value === "countQuantity") {
      setCountLabel("Count Quantity");
    } else if (value === "quantityOnHand") {
      setCountLabel("Qty on Hand");
    } else if (value === "minimumQuantity") {
      setCountLabel("Min Qty");
    } else if (value === "maximumQuantity") {
      setCountLabel("Max Qty");
    } else {
      setCountLabel("Master Count");
    }
  }

  function addModal() {
    return (
      <>
        <Paper
          sx={{
            width: "100%",
            mb: 2,
            padding: "1rem",
            position: "relative",
            // backgroundColor: "inherit",
            borderRadius: "8px",
            boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
            textAlign: "center",
          }}
        >
          <IconButton
            sx={{
              position: "absolute",
              left: "0.5rem",
              top: "0.5rem",
              color: "#fff",
              backgroundColor:
                theme.palette.customColors.secondaryCircleButtonColor,
              "&:hover": {
                backgroundColor:
                  theme.palette.customColors.secondaryCircleButtonColorHover,
              },
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>

          <span
            style={{
              fontSize: "1.5em",
              textDecoration: "underline",
              fontWeight: "bold",
            }}
          >
            Change/Save Counts
          </span>

          {/* <IconButton
            sx={{
              position: "absolute",
              top: "0.5rem",
              right: "0.5rem",
              color: "#fff",
              backgroundColor: theme.palette.customColors.secondaryCircleButtonColor,
              "&:hover": {
                backgroundColor: theme.palette.customColors.secondaryCircleButtonColorHover,
              },
            }}
            onClick={editMode ? handleSave  : handleEditMode}
          >
            {editMode ? <SaveIcon />  : <EditIcon />}
          </IconButton> */}
          <br />
          <br />
          <span
            style={{
              textDecoration: "underline",
              fontWeight: "bold",
              fontSize: "1rem",
            }}
          >
            Table Count Column
          </span>
          <br />
          <FormControl
            fullWidth
            variant="outlined"
            sx={{ mt: "10px", maxWidth: 300 }}
          >
            <InputLabel id="select-count-label">Select Type</InputLabel>
            <Select
              labelId="select-count-label"
              id="select-count"
              value={countType}
              onChange={(e) => handleCountTypeChange(e.target.value)}
              label="Select Type" // Ensure the label prop is set for proper display
            >
              <MenuItem value="countQuantity" label="Count Quantity">
                Count Quantity
              </MenuItem>
              <MenuItem value="quantityOnHand" label="Quantity on Hand">
                Quantity on Hand
              </MenuItem>
              <MenuItem value="minimumQuantity" label="Minimum Quantity">
                Minimum Quantity
              </MenuItem>
              <MenuItem value="maximumQuantity" label="Maximum Quantity">
                Maximum Quantity
              </MenuItem>
              <MenuItem
                value="masterCount"
                label="Master Count"
                style={{ color: "red" }}
              >
                Master Count (caution)
              </MenuItem>
            </Select>
          </FormControl>
          <br />
          <br />
          <Button
            variant="contained"
            color="primary"
            // onClick={handleAdd}
            // style={{ backgroundColor: "#d32f2f" }}
          >
            Save as Master Count
          </Button>
        </Paper>
      </>
    );
  }

  // View Entry Row Styling
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    border: `5px solid ${theme.palette.customColors.tableCellOutlineColor}`,
    background: `linear-gradient(45deg, ${theme.palette.customColors.darkerPrimaryColor} 30%, ${theme.palette.primary.main} 100%)`,
    color: "white",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    padding: "16px",
    // "&:first-of-type": {
    //   borderTopLeftRadius: "8px",
    //   borderBottomLeftRadius: "8px",
    // },
    // "&:last-of-type": {
    //   borderTopRightRadius: "8px",
    //   borderBottomRightRadius: "8px",
    // },
  }));

  const PlainTableCell = styled(TableCell)(({ theme }) => ({
    border: `5px solid ${theme.palette.customColors.tableCellOutlineColor}`,
    // background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.customColors.lighterPrimaryColor} 100%)`,
    // color: "white",
    // boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    padding: "16px",
    "&:first-of-type": {
      borderTopLeftRadius: "8px",
      borderBottomLeftRadius: "8px",
    },
    "&:last-of-type": {
      borderTopRightRadius: "8px",
      borderBottomRightRadius: "8px",
    },
  }));

  const TableContainerStyled = styled(TableContainer)(({ theme }) => ({
    // padding: "2px",
    // borderRadius: "8px",
    // boxShadow: "2px 3px 5px 2px rgba(255, 105, 135, .3)",
  }));

  const TableStyled = styled(Table)(({ theme }) => ({
    // margin: "auto",
    // minWidth: 650,
    // borderCollapse: "separate",
    // borderSpacing: "0 10px",
  }));

  function editModeView() {
    return (
      <Grid container spacing={1}>
        {/* On smaller screens, display as 4x1 grid */}
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="description-textfield"
            label="Description"
            defaultValue={editRow.description}
            InputProps={{
              readOnly: !editMode,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="category-textfield"
            label="Category"
            defaultValue={editRow.category}
            InputProps={{
              readOnly: !editMode,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="mfg-num-textfield"
            label="Part #"
            defaultValue={editRow.mfgNum}
            InputProps={{
              readOnly: !editMode,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="group-textfield"
            label="Group"
            defaultValue={editRow.group}
            InputProps={{
              readOnly: !editMode,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="type-textfield"
            label="Type"
            defaultValue={editRow.type}
            InputProps={{
              readOnly: !editMode,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="stage-textfield"
            label="Stage"
            defaultValue={editRow.stage}
            InputProps={{
              readOnly: !editMode,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="count-textfield"
            label="Count"
            defaultValue={editRow.count}
            InputProps={{
              readOnly: !editMode,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="unit-textfield"
            label="Unit"
            defaultValue={editRow.unit}
            InputProps={{
              readOnly: !editMode,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="cost-textfield"
            label="Cost"
            defaultValue={editRow.cost}
            InputProps={{
              readOnly: !editMode,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="qty-textfield"
            label="Qty"
            defaultValue={editRow.qty}
            InputProps={{
              readOnly: !editMode,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="vendor-textfield"
            label="Vendor"
            defaultValue={editRow.vendor}
            InputProps={{
              readOnly: !editMode,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="notes-textfield"
            label="Notes"
            defaultValue={editRow.notes}
            InputProps={{
              readOnly: !editMode,
            }}
          />
        </Grid>
      </Grid>
    );
  }

  function viewModeView() {
    return (
      <TableContainerStyled>
        <TableStyled>
          <TableBody>
            <TableRow>
              <StyledTableCell>
                <Typography>
                  <b>Description</b>
                </Typography>
              </StyledTableCell>
              <PlainTableCell>
                <Typography>{editRow.description}</Typography>
              </PlainTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>
                <Typography>
                  <b>Category</b>
                </Typography>
              </StyledTableCell>
              <PlainTableCell>
                <Typography>{editRow.category}</Typography>
              </PlainTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>
                <Typography>
                  <b>Part #</b>
                </Typography>
              </StyledTableCell>
              <PlainTableCell>
                <Typography>{editRow.mfgNum}</Typography>
              </PlainTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>
                <Typography>
                  <b>Group</b>
                </Typography>
              </StyledTableCell>
              <PlainTableCell>
                <Typography>{editRow.group}</Typography>
              </PlainTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>
                <Typography>
                  <b>Type</b>
                </Typography>
              </StyledTableCell>
              <PlainTableCell>
                <Typography>{editRow.type}</Typography>
              </PlainTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>
                <Typography>
                  <b>Stage</b>
                </Typography>
              </StyledTableCell>
              <PlainTableCell>
                <Typography>{editRow.stage}</Typography>
              </PlainTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>
                <Typography>
                  <b>Count</b>
                </Typography>
              </StyledTableCell>
              <PlainTableCell>
                <Typography>{editRow.count}</Typography>
              </PlainTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>
                <Typography>
                  <b>Unit</b>
                </Typography>
              </StyledTableCell>
              <PlainTableCell>
                <Typography>{editRow.unit}</Typography>
              </PlainTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>
                <Typography>
                  <b>Cost</b>
                </Typography>
              </StyledTableCell>
              <PlainTableCell>
                <Typography>{editRow.cost}</Typography>
              </PlainTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>
                <Typography>
                  <b>Qty</b>
                </Typography>
              </StyledTableCell>
              <PlainTableCell>
                <Typography>{editRow.qty}</Typography>
              </PlainTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>
                <Typography>
                  <b>Vendor</b>
                </Typography>
              </StyledTableCell>
              <PlainTableCell>
                <Typography>{editRow.vendor}</Typography>
              </PlainTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>
                <Typography>
                  <b>Notes</b>
                </Typography>
              </StyledTableCell>
              <PlainTableCell>
                <Typography>{editRow.notes}</Typography>
              </PlainTableCell>
            </TableRow>
          </TableBody>
        </TableStyled>
      </TableContainerStyled>
    );
  }

  function editModal() {
    if (editRow) {
      return (
        <>
          <Paper
            sx={{
              width: "100%",
              mb: 2,
              padding: "1rem",
              position: "relative",
              // backgroundColor: "inherit",
              borderRadius: "8px",
              boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
              textAlign: "center",
            }}
          >
            <IconButton
              sx={{
                position: "absolute",
                left: "0.5rem",
                top: "0.5rem",
                color: "#fff",
                backgroundColor:
                  theme.palette.customColors.secondaryCircleButtonColor,
                "&:hover": {
                  backgroundColor:
                    theme.palette.customColors.secondaryCircleButtonColorHover,
                },
              }}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>

            <span
              style={{
                fontSize: "1.5em",
                textDecoration: "underline",
                fontWeight: "bold",
              }}
            >
              {editMode ? "Edit Item" : "View Item"}
            </span>

            <IconButton
              sx={{
                position: "absolute",
                top: "0.5rem",
                right: "0.5rem",
                color: "#fff",
                backgroundColor:
                  theme.palette.customColors.secondaryCircleButtonColor,
                "&:hover": {
                  backgroundColor:
                    theme.palette.customColors.secondaryCircleButtonColorHover,
                },
              }}
              onClick={editMode ? handleSave : handleEditMode}
            >
              {editMode ? <SaveIcon /> : <EditIcon />}
            </IconButton>
            <br />
            <br />
            {editMode ? editModeView() : viewModeView()}
          </Paper>
        </>
      );
    } else {
      return null;
    }
  }

  function saveData(row) {
    console.log("push to api: " + row);
  }

  function handleCountChange(value, row) {
    // Create a new array based on initRows to avoid mutating the original state
    if (value === "") {
      value = 0;
    }
    const updatedRows = rows.map((item) =>
      item.id === row.id ? { ...item, count: value } : item
    );

    // Update the state with the new array
    setRows(updatedRows);
  }

  function mainTable() {
    return (
      <>
        <ThickInventoryHeader
          name={props.name}
          title={props.title}
          rows={rows}
          setRows={setRows}
          handleAddMode={handleAddMode}
          setFilteredRows={setFilteredRows}
          setPage={setPage}
          backButton={props.backButton}
        />
        <Box sx={{ width: "100%" }}>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <TableContainer sx={{ maxHeight: "60vh" }}>
              <Table
                stickyHeader
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={"small"}
              >
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  countType={countType}
                  countLabel={countLabel}
                />
                <TableBody>
                  {visibleRows.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={row.id}
                        sx={{ cursor: "pointer" }}
                      >
                        <TableCell></TableCell>
                        <TableCell style={{ minWidth: "235px" }}>
                          {row.description}
                        </TableCell>
                        <TableCell>
                          <TextField
                            type="text" // Using text to handle custom validation
                            value={row.count}
                            onChange={(e) => {
                              let value = e.target.value;

                              // Allow "0" as a valid input on its own
                              if (value === "0") {
                                handleCountChange(value, row);
                              } else {
                                // Remove leading zeros for all other cases
                                value = value.replace(/^0+/, "");

                                // Ensure the value is numeric
                                if (/^\d*$/.test(value)) {
                                  handleCountChange(value, row);
                                }
                              }
                            }}
                            onBlur={(e) => {
                              saveData(row);
                            }}
                            variant="outlined"
                            sx={{
                              "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                                {
                                  "-webkit-appearance": "none",
                                  margin: 0,
                                },
                              "& input[type=number]": {
                                "-moz-appearance": "textfield",
                              },
                            }}
                          />
                        </TableCell>
                        {/* <TableCell>{row.category}</TableCell>
                        <TableCell>{row.mfgNum}</TableCell>
                        <TableCell>{row.group}</TableCell>
                        <TableCell>{row.type}</TableCell>

                        <TableCell>{row.stage}</TableCell>
                        <TableCell>{row.count}</TableCell>
                        <TableCell>
                          <Checkbox
                            checked={row.printBool}
                            color="primary"
                            inputProps={{ 'aria-label': 'Print Checkbox' }}
                          />
                        </TableCell>
                        {/* <TableCell>{row.unit}</TableCell>
                        <TableCell>{row.cost}</TableCell>
                        <TableCell>{row.qty}</TableCell>
                        <TableCell>{row.vendor}</TableCell>
                        <TableCell>{row.notes}</TableCell> */}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[25, 100, 500]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      </>
    );
  }

  const getKey = () => {
    if (addMode) return 0; // Assuming index 0 is for addModal
    if (editRow) return 1; // Assuming index 1 is for editModal
    return 2; // Assuming index 2 is for mainTable
  };

  const compArr = [
    <div key="addModal">{addModal()}</div>,
    <div key="editModal">{editModal()}</div>,
    <div key="mainTable">{mainTable()}</div>,
  ];

  return (
    <div>
      <CasaSnackbar
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={"Saved successfully."}
      />
      <SwitchTransition>
        <CSSTransition
          key={getKey()}
          timeout={150}
          classNames="fade-inventory"
          unmountOnExit
        >
          {compArr[getKey()]}
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
}
