import React, { useRef } from "react";
import { Avatar, Button, Typography } from "@mui/material";
import CasaAppIcon from "../../Resuable/CasaAppIcon/CasaAppIcon";
import { useAdministrationContext } from "../../../contexts/AdministrationContext";
import { useCasaWindowContext } from "../../../contexts/CasaWindowContext";

const ViewEmployee = (props) => {
  const { administrationState } = useAdministrationContext(); //updateAdministration
  const { casaWindowState } = useCasaWindowContext();
  // Container width state and ref
  const appContainerRef = useRef(null);
  const avatarImagePath = require("../../../images/default_avi.png");

  return (
    <div
      ref={appContainerRef}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        marginTop: "-15vh",
        overflow: "hidden",
      }}
    >
      <>
        <Avatar
          src={avatarImagePath}
          style={{ width: "6em", height: "6em", marginBottom: "8px" }}
        />
        <Typography
          variant="h5"
          color="white"
          style={{
            display: "flex",
            alignItems: "center",
            paddingTop: "4px",
            textDecoration: "underline",
            fontWeight: "bold",
          }}
        >
          {administrationState.employeeName}
        </Typography>

        <Button
          style={{ marginTop: "8px" }}
          variant="contained"
          color="primary"
          onClick={props.backButton}
        >
          Back
        </Button>
        <div style={{ display: "flex", marginTop: "3vh" }}>
          <CasaAppIcon
            title="Information"
            width={casaWindowState.width}
            color="#08005C"
            altColor="#0D0078"
            linkto={`/administration/search-employees/view/information`}
          />
          <CasaAppIcon
            title="Permissions"
            width={casaWindowState.width}
            color="#B60080"
            altColor="#934747"
            linkto={`/administration/search-employees/view/permissions`}
          />
          <CasaAppIcon
            title="Reports"
            width={casaWindowState.width}
            color="#C18700"
            altColor="#BFA836"
            linkto={`/administration/search-employees/view/reports`}
          />
          <CasaAppIcon
            title="Apps"
            width={casaWindowState.width}
            color="#D84700"
            altColor="#FFA836"
            linkto={`/administration/search-employees/view/apps`}
          />
        </div>
      </>
    </div>
  );
};

export default ViewEmployee;
