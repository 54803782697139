import { createTheme } from "@mui/material/styles";

// Default (Light Mode) Theme
const lightModeTheme = createTheme({
  typography: {
    fontSize: 16, // Base font size

    h6: {
      fontSize: 18, // Subtitles
    },
    subtitle1: {
      fontSize: 16, // Subtitles
    },
    menuTitle: {
      fontSize: 22,
    },
    findSearchSubtitle: {
      fontSize: 20,
    },
    filterViewTitle: {
      fontSize: 32,
    },
  },
  palette: {
    mode: "light",
    primary: {
      main: "#0e570b",
    },
    background: {
      default: "#272727",
      light: "#b3b3b3", // used for when you want contrast, but lighter than standard background
      frontPaper: "#ffffff", // used for when you need paper for components in front of paper
    },
    customColors: {
      // Used for primary color text, or things tht need to be primary-ish but brighter
      lighterPrimaryColor: "#0e801d",
      darkerPrimaryColor: "#0C4E09",

      tableCellOutlineColor: "#111111",

      // Used for color of CasaAppBar
      casaAppBarColor: "#010101",

      // Used for buttons that hang off corners (usually primary color - e.g. inventory add)
      circleButtonColor: "#0e570b",
      circleButtonColorHover: "#1ba816",

      // Used for buttons that are inside (usually secondary color - e.g. inventory edit/close)
      secondaryCircleButtonColor: "#456",
      secondaryCircleButtonColorHover: "#123",

      // Used for table header background color (e.g. Inventory view/edit screen)
      tableHeaderCellBackgroundColor: "#0e570b",

      // Used for background color on windows (e.g. Administration Permissions/Reports windows)
      windowHeadingColor: "#101010",

      // Used for background color on screen titles (e.g. Administration initial main window)
      screenHeadingColor: "#101010",

      // Used for the second hand on the time clock
      timeClockSecondHandColor: "#0e570b",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: "#ffffff",
          fontWeight: "bold",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          border: "4px solid #222222", // Adds a white outline to all Paper components
        },
      },
    },
  },
});

// Dark Mode Theme
const darkModeTheme = createTheme({
  typography: {
    fontSize: 16, // Base font size

    h6: {
      fontSize: 18, // Subtitles
    },
    subtitle1: {
      fontSize: 16, // Subtitles
    },
    menuTitle: {
      fontSize: 22,
    },
    findSearchSubtitle: {
      fontSize: 20,
    },
    filterViewTitle: {
      fontSize: 32,
    },
  },
  palette: {
    mode: "dark",
    primary: {
      main: "#007d02",
    },
    background: {
      default: "#040404",
      paper: "#222222",
      light: "#080808",
      frontPaper: "#333333",
    },
    customColors: {
      lighterPrimaryColor: "#0bb321",
      darkerPrimaryColor: "#083406",
      tableCellOutlineColor: "#e0e0e0",
      casaAppBarColor: "#0e510b",
      circleButtonColor: "#007d02",
      circleButtonColorHover: "#1ba816",
      secondaryCircleButtonColor: "#456",
      secondaryCircleButtonColorHover: "#123",
      tableHeaderCellBackgroundColor: "#101010",
      windowHeadingColor: "#181818",
      screenHeadingColor: "#181818",
      timeClockSecondHandColor: "#007d02",
    },
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#BDBDBD", // Light label color
          "&.Mui-focused": {
            color: "#00db1d", // Slightly darker when focused
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#00db1d", // Darker outline when focused
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: "#ffffff",
          fontWeight: "bold",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          border: "2px solid grey", // Adds a white outline to all Paper components
        },
      },
    },
  },
});

// Texas Mode Theme
const texasModeTheme = createTheme({
  typography: {
    fontSize: 16, // Base font size

    h6: {
      fontSize: 18, // Subtitles
    },
    subtitle1: {
      fontSize: 16, // Subtitles
    },
    menuTitle: {
      fontSize: 22,
    },
    findSearchSubtitle: {
      fontSize: 20,
    },
    filterViewTitle: {
      fontSize: 32,
    },
  },
  palette: {
    mode: "light",
    primary: {
      main: "#9e4700",
    },
    background: {
      default: "#272727",
      light: "#b3b3b3",
      frontPaper: "#ffffff",
    },
    customColors: {
      lighterPrimaryColor: "#9e4700",
      darkerPrimaryColor: "#6E3100",
      tableCellOutlineColor: "#333333",
      casaAppBarColor: "#9e4700",
      circleButtonColor: "#9e4700",
      circleButtonColorHover: "#9e4700",
      secondaryCircleButtonColor: "#456",
      secondaryCircleButtonColorHover: "#123",
      tableHeaderCellBackgroundColor: "#9e4700",
      windowHeadingColor: "#101010",
      screenHeadingColor: "#101010",
      timeClockSecondHandColor: "#9e4700",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: "#ffffff",
          fontWeight: "bold",
        },
      },
    },
  },
});

// Theme for test purposes: helps visualize which colors are customizable
const testMagentaTheme = createTheme({
  typography: {
    fontSize: 16, // Base font size

    h6: {
      fontSize: 18, // Subtitles
    },
    subtitle1: {
      fontSize: 16, // Subtitles
    },
    menuTitle: {
      fontSize: 22,
    },
    findSearchSubtitle: {
      fontSize: 20,
    },
    filterViewTitle: {
      fontSize: 32,
    },
  },
  palette: {
    mode: "light",
    primary: {
      main: "#ff00ff",
    },
    background: {
      default: "#ff00ff",
      light: "#ff00ff",
      frontPaper: "#ff00ff",
    },
    customColors: {
      lighterPrimaryColor: "#ff00ff",
      darkerPrimaryColor: "#ff00ff",
      tableCellOutlineColor: "#ff00ff",
      casaAppBarColor: "#ff00ff",
      circleButtonColor: "#ff00ff",
      circleButtonColorHover: "#ff00ff",
      secondaryCircleButtonColor: "#ff00ff",
      secondaryCircleButtonColorHover: "#ff00ff",
      tableHeaderCellBackgroundColor: "#ff00ff",
      windowHeadingColor: "#ff00ff",
      screenHeadingColor: "#ff00ff",
      timeClockSecondHandColor: "#ff00ff",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: "#ffffff",
          fontWeight: "bold",
        },
      },
    },
  },
});

export { lightModeTheme, darkModeTheme, texasModeTheme, testMagentaTheme };
