// CasaConfigureComponent.js
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { useAdministrationContext } from "../../../contexts/AdministrationContext";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CasaSwitchForm from "../CasaSwitchForm/CasaSwitchForm";
import { useState } from "react";
import { useInventoryContext } from "../../../contexts/InventoryContext";

function updateEmployeeInfo() {
  console.log("save employee info via api call");
}

const InformationWindow = (props) => {
  return (
    <Box sx={{ padding: "20px", maxWidth: "1200px", margin: "auto" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" style={{ marginTop: "5px" }}>
            <u>
              <b>Employee Details</b>
            </u>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="threeIntIDText"
            label="Three Int ID"
            disabled={!props.editMode}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="firstNameText"
            label="First Name"
            disabled={!props.editMode}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="lastNameText"
            label="Last Name"
            disabled={!props.editMode}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="midInitialText"
            label="Middle Initial"
            disabled={!props.editMode}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="streetAddressText"
            label="Street Address"
            disabled={!props.editMode}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            name="addressCityText"
            label="City"
            disabled={!props.editMode}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            name="addressStateText"
            label="State"
            disabled={!props.editMode}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            name="addressZipText"
            label="Zip Code"
            disabled={!props.editMode}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="hireDateText"
            label="Hire Date"
            type="date"
            InputLabelProps={{ shrink: true }}
            disabled={!props.editMode}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="statusText"
            label="Status"
            disabled={!props.editMode}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="notesText"
            label="Notes"
            multiline
            rows={4}
            disabled={!props.editMode}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="departmentText"
            label="Department"
            disabled={!props.editMode}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="emergencyPhoneText"
            label="Emergency Phone"
            disabled={!props.editMode}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="emergencyContactNameText"
            label="Emergency Contact Name"
            disabled={!props.editMode}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="homePhoneText"
            label="Home Phone"
            disabled={!props.editMode}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="mobilePhoneText"
            label="Mobile Phone"
            disabled={!props.editMode}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="workPhoneText"
            label="Work Phone"
            disabled={!props.editMode}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="birthDateText"
            label="Birth Date"
            type="date"
            InputLabelProps={{ shrink: true }}
            disabled={!props.editMode}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="ssnText"
            label="SSN"
            disabled={!props.editMode}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="dlnText"
            label="DLN"
            disabled={!props.editMode}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const PermissionsWindow = (props) => {
  return (
    <Box sx={{ padding: "20px", maxWidth: "1200px", margin: "auto" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" style={{ marginTop: "5px" }}>
            <u>
              <b>Time Clock</b>
            </u>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Clock Message"
            variant="outlined"
            fullWidth
            disabled={!props.editMode}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Time Clock #"
            variant="outlined"
            fullWidth
            disabled={!props.editMode}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Note"
            variant="outlined"
            fullWidth
            disabled={!props.editMode}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox disabled={!props.editMode} />}
              label="Not Using the Time Clock (Do not include in time reports)"
            />
            <FormControlLabel
              control={<Checkbox disabled={!props.editMode} />}
              label="Part Time (Do not track on 'not clocked in' report)"
            />
            <FormControlLabel
              control={<Checkbox disabled={!props.editMode} />}
              label="Allow employee to 'Clock In' at any time"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox disabled={!props.editMode} />}
              label="Display 'Rain Out' message if it exists and prevent 'Clock In'"
            />
            <FormControlLabel
              control={<Checkbox disabled={!props.editMode} />}
              label="Remote Time Clock"
            />
          </FormGroup>
        </Grid>

        <Grid item xs={12}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              <u>
                <b>Payroll</b>
              </u>
            </Typography>
          </Grid>
          <FormControl variant="outlined" fullWidth disabled={!props.editMode}>
            <InputLabel>Payroll Type</InputLabel>
            <Select label="Payroll Type" value="GP Hours">
              <MenuItem value="GP Hours">GP Hours</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            <u>
              <b>Additional Options</b>
            </u>
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox disabled={!props.editMode} />}
                  label="Unlock Invoices"
                />
                <FormControlLabel
                  control={<Checkbox disabled={!props.editMode} />}
                  label="Customer Hold"
                />
                <FormControlLabel
                  control={<Checkbox disabled={!props.editMode} />}
                  label="Job Hold"
                />
                <FormControlLabel
                  control={<Checkbox disabled={!props.editMode} />}
                  label="Bin Hold"
                />
                <FormControlLabel
                  control={<Checkbox disabled={!props.editMode} />}
                  label="Equip Check Out"
                />
                <FormControlLabel
                  control={<Checkbox disabled={!props.editMode} />}
                  label="Set Rain Out"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox disabled={!props.editMode} />}
                  label="Mass Update"
                />
                <FormControlLabel
                  control={<Checkbox disabled={!props.editMode} />}
                  label="Pull Sheets"
                />
                <FormControlLabel
                  control={<Checkbox disabled={!props.editMode} />}
                  label="Change Dept"
                />
                <FormControlLabel
                  control={<Checkbox disabled={!props.editMode} />}
                  label="Change Piping"
                />
                <FormControlLabel
                  control={<Checkbox disabled={!props.editMode} />}
                  label="Edit PO Date"
                />
                <FormControlLabel
                  control={<Checkbox disabled={!props.editMode} />}
                  label="Inv Sched Notes"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox disabled={!props.editMode} />}
                  label="Inv Read Only"
                />
                <FormControlLabel
                  control={<Checkbox disabled={!props.editMode} />}
                  label="Announcements"
                />
                <FormControlLabel
                  control={<Checkbox disabled={!props.editMode} />}
                  label="Time Clock Admin"
                />
                <FormControlLabel
                  control={<Checkbox disabled={!props.editMode} />}
                  label="Payroll"
                />
                <FormControlLabel
                  control={<Checkbox disabled={!props.editMode} />}
                  label="Edit Employees"
                />
              </FormGroup>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

const ReportsWindow = (props) => {
  const [report, setReport] = React.useState("");
  const { administrationState } = useAdministrationContext(); //updateAdministration

  const reportsList = [
    "W2_2023.pdf",
    "W2_2024.pdf",
    "Payroll_Lifetime.pdf",
    "Summary.pdf",
  ];

  const handleChange = (event) => {
    setReport(event.target.value);
  };

  return (
    <Box
      sx={{
        padding: { xs: "10px", sm: "20px" },
        maxWidth: "1200px",
        margin: "auto",
        textAlign: "center",
      }}
    >
      <Grid container spacing={3} justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Typography
            variant="findSearchSubtitle"
            sx={{
              fontWeight: "bold",
            }}
          >
            {reportsList.length} Reports Available
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl
            variant="outlined"
            sx={{ minWidth: 200, maxWidth: "60vw" }}
          >
            <InputLabel id="report-select-label">Report Type</InputLabel>
            <Select
              labelId="report-select-label"
              value={report}
              onChange={handleChange}
              label="Report Type"
            >
              {reportsList.map((reportName) => (
                <MenuItem key={reportName} value={reportName}>
                  {administrationState.employeeName.replace(/ /g, "_") +
                    "_" +
                    reportName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={updateEmployeeInfo}
            disabled={!report}
          >
            Print
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

const AppsWindow = (props) => {
  return (
    <Box
      sx={{
        padding: { xs: "10px", sm: "20px" },
        maxWidth: "1200px",
        margin: "auto",
        textAlign: "center",
      }}
    >
      <CasaSwitchForm />
    </Box>
  );
};

const CustomerWindow = (props) => {
  return (
    <Grid container spacing={2} sx={{ padding: "40px" }}>
      {/* Section 1: Customer Information */}
      <Grid item xs={12}>
        <Typography variant="h6" sx={{ textDecoration: "underline" }}>
          Customer Information
        </Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField label="Customer" fullWidth disabled={!props.editMode} />
      </Grid>
      <Grid item xs={6} md={6}>
        <TextField label="First Name" fullWidth disabled={!props.editMode} />
      </Grid>
      <Grid item xs={6} md={6}>
        <TextField label="Type" fullWidth disabled={!props.editMode} />
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField label="Address" fullWidth disabled={!props.editMode} />
      </Grid>

      {/* Section 2: System Details */}
      <Grid item xs={12}>
        <Typography variant="h6" sx={{ textDecoration: "underline" }}>
          System Details
        </Typography>
      </Grid>
      <Grid item xs={6} md={4}>
        <TextField label="System ID" fullWidth disabled={!props.editMode} />
      </Grid>
      <Grid item xs={6} md={4}>
        <TextField label="Date" fullWidth disabled={!props.editMode} />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField label="DLP" fullWidth disabled={!props.editMode} />
      </Grid>

      {/* Section 3: Location */}
      <Grid item xs={12}>
        <Typography variant="h6" sx={{ textDecoration: "underline" }}>
          Location
        </Typography>
      </Grid>
      <Grid item xs={4} md={4}>
        <TextField label="City" fullWidth disabled={!props.editMode} />
      </Grid>
      <Grid item xs={4} md={4}>
        <TextField label="State" fullWidth disabled={!props.editMode} />
      </Grid>
      <Grid item xs={4} md={4}>
        <TextField label="Zip Code" fullWidth disabled={!props.editMode} />
      </Grid>

      {/* Section 4: Contact Details */}
      <Grid item xs={12}>
        <Typography variant="h6" sx={{ textDecoration: "underline" }}>
          Contact Details
        </Typography>
      </Grid>
      <Grid item xs={4} md={4}>
        <TextField label="Phone" fullWidth disabled={!props.editMode} />
      </Grid>
      <Grid item xs={4} md={4}>
        <TextField label="Fax" fullWidth disabled={!props.editMode} />
      </Grid>
      <Grid item xs={4} md={4}>
        <TextField label="Email" fullWidth disabled={!props.editMode} />
      </Grid>

      {/* Section 5: Billing Information */}
      <Grid item xs={12}>
        <Typography variant="h6" sx={{ textDecoration: "underline" }}>
          Billing Information
        </Typography>
      </Grid>
      <Grid item xs={4} md={4}>
        <TextField label="Accounting ID" fullWidth disabled={!props.editMode} />
      </Grid>
      <Grid item xs={4} md={4}>
        <TextField label="Billing Code" fullWidth disabled={!props.editMode} />
      </Grid>
      <Grid item xs={4} md={4}>
        <TextField label="Credit Card" fullWidth disabled={!props.editMode} />
      </Grid>

      {/* Section 6: Additional Options */}
      <Grid item xs={12}>
        <Typography variant="h6" sx={{ textDecoration: "underline" }}>
          Additional Options
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControlLabel
          control={<Checkbox disabled={!props.editMode} />}
          label="Place Customer on Hold"
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControlLabel
          control={<Checkbox disabled={!props.editMode} />}
          label="PO Required for Misc Job"
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControlLabel
          control={<Checkbox disabled={!props.editMode} />}
          label="Two Year Warranty"
        />
      </Grid>

      {/* Section 7: Estimators */}
      <Grid item xs={12}>
        <Typography variant="h6" sx={{ textDecoration: "underline" }}>
          Estimators
        </Typography>
      </Grid>
      <Grid item xs={6} md={6}>
        <TextField label="AC Estimator" fullWidth disabled={!props.editMode} />
      </Grid>
      <Grid item xs={6} md={6}>
        <TextField label="PL Estimator" fullWidth disabled={!props.editMode} />
      </Grid>

      {/* Section 8: Notes */}
      <Grid item xs={12}>
        <Typography variant="h6" sx={{ textDecoration: "underline" }}>
          Notes
        </Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField
          label="Notes"
          multiline
          rows={4}
          fullWidth
          disabled={!props.editMode}
        />
      </Grid>

      {/* Section 9: Contacts */}
      <Grid item xs={12}>
        <Typography variant="h6" sx={{ textDecoration: "underline" }}>
          Contacts
        </Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Email</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Adam Wilson</TableCell>
              <TableCell>(210) 559-6096</TableCell>
              <TableCell>awilson@adamwilsonhomes.com</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Ben Hudson</TableCell>
              <TableCell>(210) 426-9277</TableCell>
              <TableCell>ben@adamwilsonhomes.com</TableCell>
            </TableRow>
            {/* Add other rows as needed */}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};

const EditInventoryItem = (props) => {
  const { inventoryState, updateInventory } = useInventoryContext();
  const [filtersLoaded, setFiltersLoaded] = React.useState(false);

  function handleUpdateInventory(type, value) {
    if (type === "type") {
      updateInventory({
        createType: value,
        createCategory: "",
        createGroup: "",
      });
      // get rows returned from api attached to the json obj w/ parent id
      const results = inventoryState.filters.find(
        (filter) => filter.parentId === value
      )?.relatedRows;
      if (results) {
        updateInventory({ createCategoryOptions: results });
      }
      updateInventory({ createGroupOptions: [] });
    } else if (type === "category") {
      updateInventory({
        createCategory: value,
        createGroup: "",
      });
      const results = inventoryState.filters.find(
        (filter) => filter.parentId === value
      )?.relatedRows;
      if (results) {
        updateInventory({ createGroupOptions: results });
      } else {
        updateInventory({ createGroupOptions: [] });
      }
    } else if (type === "group") {
      updateInventory({
        createGroup: value,
      });
    }
  }

  return (
    <Grid container spacing={2} sx={{ padding: "40px" }}>
      {/* Section 1: Customer Information */}
      <Grid item xs={12}>
        <Typography variant="h6" sx={{ textDecoration: "underline" }}>
          Basic Information
        </Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField
          fullWidth
          id="description-textfield"
          label="Description"
          defaultValue={props.editRow.InvDescription}
          InputProps={{
            readOnly: !props.editMode,
          }}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField
          fullWidth
          id="mfg-num-textfield"
          label="Part #"
          defaultValue={props.editRow.InvPart}
          InputProps={{
            readOnly: !props.editMode,
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography
          variant="h6"
          sx={{ textDecoration: "underline", paddingTop: "25px" }}
        >
          Group Categories
        </Typography>
      </Grid>

      <Grid item xs={12} md={4}>
        <FormControl
          fullWidth
          variant="outlined"
          sx={{
            textAlign: "left",
          }}
        >
          <InputLabel id="type-label">
            {!filtersLoaded ? "Loading..." : "Type"}
          </InputLabel>
          <Select
            fullWidth
            labelId="type-label"
            id="type"
            value={inventoryState.createType}
            onChange={(event) => {
              handleUpdateInventory("type", event.target.value);
            }}
            label={!filtersLoaded ? "Loading..." : "Type"}
            // disabled={!filtersLoaded}
            disabled
          >
            {inventoryState.createTypeOptions.map((option) => (
              <MenuItem key={option.LevelID} value={option.LevelID}>
                {option.LevelDesc}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControl
          fullWidth
          variant="outlined"
          sx={{
            textAlign: "left",
          }}
        >
          <InputLabel id="category-label">
            {!filtersLoaded ? "Loading..." : "Category"}
          </InputLabel>
          <Select
            fullWidth
            labelId="category-label"
            id="category"
            value={inventoryState.createCategory}
            onChange={(event) => {
              handleUpdateInventory("category", event.target.value);
            }}
            label={!filtersLoaded ? "Loading..." : "Category"}
            // disabled={
            //   inventoryState.createCategoryOptions.length === 0 ||
            //   inventoryState.createType === "" ||
            //   !filtersLoaded
            // }
            disabled
          >
            {inventoryState.createCategoryOptions.map((option) => (
              <MenuItem key={option.LevelID} value={option.LevelID}>
                {option.LevelDesc}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControl
          fullWidth
          variant="outlined"
          sx={{
            textAlign: "left",
          }}
        >
          <InputLabel id="group-label">
            {!filtersLoaded ? "Loading..." : "Group"}
          </InputLabel>
          <Select
            fullWidth
            labelId="group-label"
            id="group"
            value={inventoryState.createGroup}
            onChange={(event) => {
              handleUpdateInventory("group", event.target.value);
            }}
            label={!filtersLoaded ? "Loading..." : "Group"}
            // disabled={
            //   inventoryState.createGroupOptions.length === 0 ||
            //   inventoryState.createCategory === "" ||
            //   !filtersLoaded
            // }
            disabled
          >
            {inventoryState.createGroupOptions.map((option) => (
              <MenuItem key={option.LevelID} value={option.LevelID}>
                {option.LevelDesc}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <Typography
          variant="h6"
          sx={{ textDecoration: "underline", paddingTop: "25px" }}
        >
          Unit Details
        </Typography>
      </Grid>

      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          id="cost-textfield"
          label="Cost"
          defaultValue={props.editRow.InvCost}
          InputProps={{
            readOnly: !props.editMode,
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          id="unit-textfield"
          label="Unit"
          defaultValue={props.editRow.InvUnit}
          InputProps={{
            readOnly: !props.editMode,
          }}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          id="qty-textfield"
          label="Qty"
          defaultValue={props.editRow.InvQuantity}
          InputProps={{
            readOnly: !props.editMode,
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography
          variant="h6"
          sx={{ textDecoration: "underline", paddingTop: "25px" }}
        >
          Extras
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          id="notes-textfield"
          label="Notes"
          defaultValue={props.editRow.InvNotes}
          InputProps={{
            readOnly: !props.editMode,
          }}
        />
      </Grid>
    </Grid>
  );
};

const CasaConfigureComponent = (props) => {
  const theme = useTheme();
  const [editMode, setEditMode] = useState(false);

  return (
    <div>
      <Paper
        sx={{
          width: "100%", // Full width of its container
          maxWidth: "1500px", // Maximum width if needed
          margin: "auto", // Center the Paper component
          display: "flex",
          flexDirection: "column",
          maxHeight: "80vh", // Adjust height as needed
          overflow: "hidden", // Hide overflow to keep content within Paper
        }}
      >
        {/* Header Section */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            padding: "20px",
            borderBottom: `1px solid ${theme.palette.divider}`,
            boxShadow: `0 8px 12px -1px rgba(0, 0, 0, 0.3), 0 4px 8px -2px rgba(0, 0, 0, 0.2)`,
            backgroundColor: theme.palette.background.paper,
          }}
        >
          {/* Close Icon */}
          <IconButton
            sx={{
              color: "#fff",
              border: "1px solid #fff",
              backgroundColor:
                theme.palette.customColors.secondaryCircleButtonColor,
              "&:hover": {
                backgroundColor:
                  theme.palette.customColors.secondaryCircleButtonColorHover,
              },
            }}
            onClick={props.closeModalFunction}
          >
            <CloseIcon />
          </IconButton>

          {/* Title */}
          <Typography
            variant="h4"
            sx={{ flexGrow: 1, textAlign: "center", margin: "0 20px" }}
          >
            <u>
              <b>{props.title}</b>
            </u>
          </Typography>

          {/* Enable Edit/Save Icon */}
          {props.editEnabled && (
            <IconButton
              sx={{
                color: "#fff",
                border: "1px solid #fff",
                backgroundColor:
                  theme.palette.customColors.secondaryCircleButtonColor,
                "&:hover": {
                  backgroundColor:
                    theme.palette.customColors.secondaryCircleButtonColorHover,
                },
              }}
              onClick={() => {
                if (editMode) {
                  props.handleSave();
                }
                setEditMode(!editMode);
              }}
            >
              {editMode ? <SaveIcon /> : <EditIcon />}
            </IconButton>
          )}
          {!props.editEnabled && (
            <IconButton
              sx={{
                color: "#ffffff00 !important",
                backgroundColor: "#ffffff00",
              }}
              disabled={true}
            >
              {editMode ? <SaveIcon /> : <EditIcon />}
            </IconButton>
          )}
        </Box>

        {/* Scrollable Form Section */}
        <Box
          sx={{
            flex: 1, // Takes up the remaining space
            overflowY: "auto", // Enables vertical scrolling
            paddingTop: "5px",
            paddingBottom: "20px",
            paddingRight: "20px",
            paddingLeft: "20px",
          }}
        >
          {props.title === "Information" && (
            <InformationWindow editMode={editMode} />
          )}
          {props.title === "Permissions" && (
            <PermissionsWindow editMode={editMode} />
          )}
          {props.title === "Reports" && <ReportsWindow editMode={editMode} />}
          {props.title === "Apps" && <AppsWindow editMode={editMode} />}
          {props.title === "Customer" && <CustomerWindow editMode={editMode} />}
          {props.title === "Item Details" && (
            <EditInventoryItem editMode={editMode} editRow={props.editRow} />
          )}
        </Box>
      </Paper>
    </div>
  );
};

export default CasaConfigureComponent;
