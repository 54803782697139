import React from "react";
import {
  Paper,
  Grid,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Checkbox,
  FormControlLabel,
  Button,
  Typography,
  Box,
  useTheme,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import CasaCloseButton from "../../Resuable/CasaCloseButton/CasaCloseButton";

const TimeclockAdmin = (props) => {
  const [selectedDate, setSelectedDate] = React.useState(dayjs());
  const [name, setName] = React.useState('Herrera Jr, Jesus "Jesse"');
  const [reason, setReason] = React.useState("Vacation");
  const [timeIn, setTimeIn] = React.useState("");
  const [timeOut, setTimeOut] = React.useState("");
  const [notes, setNotes] = React.useState("");
  const [sanAntonioEntry, setSanAntonioEntry] = React.useState(false);
  const [timeOutNextDay, setTimeOutNextDay] = React.useState(false);
  const theme = useTheme();

  return (
    <div>
      <Paper
        sx={{
          width: "100%",
          mb: 2,
          position: "relative",
          borderRadius: "8px",
          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
          textAlign: "center",
        }}
      >
        <CasaCloseButton handleClose={props.backButton} />
        <Box
          sx={{
            backgroundColor: theme.palette.customColors.screenHeadingColor, // Update with your desired color
            padding: "10px", // Adjust the padding
            textAlign: "center", // Center-align text
          }}
        >
          <Typography
            variant="h5"
            color="white"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div style={{ flex: 1, textAlign: "center" }}>
              <b>{props.name}</b>
            </div>
          </Typography>
        </Box>
        <br />
        <div
          style={{
            width: "70%",
            margin: "0 auto",
            display: "block",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid container spacing={2}>
              {/* Row 1: Date */}
              <Grid item xs={12}>
                <DatePicker
                  label="Time Clock Date"
                  value={selectedDate}
                  onChange={(newValue) => setSelectedDate(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </Grid>

              {/* Row 2: Name, Time In, Time Out */}
              <Grid item xs={12} md={4}>
                <TextField
                  label="Select Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  select
                  fullWidth
                >
                  <MenuItem value="Herrera Jr, Jesus 'Jesse'">
                    Herrera Jr, Jesus "Jesse"
                  </MenuItem>
                  {/* Add more options as necessary */}
                </TextField>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Time In"
                  type="time"
                  value={timeIn}
                  onChange={(e) => setTimeIn(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Time Out"
                  type="time"
                  value={timeOut}
                  onChange={(e) => setTimeOut(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                  fullWidth
                />
              </Grid>

              {/* Row 3: Reason, Lunch */}
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Reason</InputLabel>
                  <Select
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    label="Reason"
                  >
                    <MenuItem value="Vacation">Vacation</MenuItem>
                    {/* Add more options as necessary */}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Lunch Time (in minutes)"
                  type="number"
                  fullWidth
                />
              </Grid>

              {/* Row 4: Checkboxes */}
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sanAntonioEntry}
                      onChange={(e) => setSanAntonioEntry(e.target.checked)}
                    />
                  }
                  label="San Antonio Time Entry"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={timeOutNextDay}
                      onChange={(e) => setTimeOutNextDay(e.target.checked)}
                    />
                  }
                  label="Time Out the Following Day"
                />
              </Grid>

              {/* Row 5: Notes */}
              <Grid item xs={12}>
                <TextField
                  label="Notes"
                  multiline
                  rows={4}
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  fullWidth
                />
              </Grid>
            </Grid>
          </LocalizationProvider>

          <br />
          <Button variant="contained" color="primary">
            ADD
          </Button>
          <br />
          <br />
        </div>
      </Paper>
    </div>
  );
};

export default TimeclockAdmin;
