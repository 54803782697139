// InventoryFolder.js
import React, { useEffect } from "react";
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CasaCloseButton from "../../Resuable/CasaCloseButton/CasaCloseButton";
import { useAPIContext } from "../../../contexts/APIContext";
import { GetUserAndTokens } from "../../../api/GetUserAndTokens";
import axios from "axios";
import { useUserStateContext } from "../../../contexts/UserStateContext";
import { useInventoryContext } from "../../../contexts/InventoryContext";
import { useNavigate } from "react-router-dom";

const InventoryCreate = (props) => {
  const theme = useTheme();
  const { APIState } = useAPIContext();
  const { userState } = useUserStateContext();
  const { inventoryState, updateInventory } = useInventoryContext();
  const [filtersLoaded, setFiltersLoaded] = React.useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState.department]);

  async function handleAdd() {
    if (
      inventoryState.createType === "" ||
      inventoryState.createCategory === "" ||
      inventoryState.createGroup === "" ||
      inventoryState.createDescription === ""
    ) {
      alert("Please provide a type, category, group, and description.");
    } else {
      const response = await createNewItem();
      if (Object.entries(response).length > 0) {
        navigate("/inventory/search");
      } else {
        alert("Failed to create item.");
      }
    }
  }

  async function getFilters() {
    /* 
      This is where the app will call the API to populate
      userState with the logged in user's data in UserStateContext.
    */
    try {
      setFiltersLoaded(false);
      updateInventory({
        createType: "",
        createTypeOptions: [],
        createCategory: "",
        createCategoryOptions: [],
        createGroup: "",
        createGroupOptions: [],
      });
      const userAuthObj = await GetUserAndTokens();
      let response;
      response = await axios.get(APIState.InvAPI, {
        params: {
          InvFunc: "GetInvCat",
          GetLevel: 0,
          Level1Parm: 0,
          Level2Parm: 0,
          Level3Parm: 0,
        },
        headers: {
          Authorization: userAuthObj.idToken,
        },
      });

      if (!Array.isArray(response.data) || response.data === null) {
        console.log("No results, or empty/null response from API.");
        setFiltersLoaded(true);
        return [];
      } else {
        const dept =
          response.data[0]?.relatedRows[
            userState.getDepartmentIndex(userState.department)
          ]?.LevelID;
        // Finally, retrieve the options for that ID to get the correct data
        const options = response.data.find(
          (filter) => filter.parentId === dept
        );
        updateInventory({
          filters: response.data,
          createTypeOptions: options.relatedRows,
        });
        setFiltersLoaded(true);
        return response.data;
      }
    } catch (e) {
      console.error("GET call failed: ", e);
      setFiltersLoaded(true);
      return [];
    }
  }

  async function createNewItem() {
    /* 
      This is where the app will call the API to populate
      userState with the logged in user's data in UserStateContext.
    */
    try {
      const userAuthObj = await GetUserAndTokens();

      let response;
      response = await axios.get(APIState.InvAPI, {
        params: {
          InvFunc: "NewInvItem",
          Level1Parm: parseInt(userState.getDepartmentIndex() + 1),
          Level2Parm: inventoryState.createType.split("-")[1],
          InvCategory: inventoryState.createCategory.split("-")[1],
          InvGroup: inventoryState.createGroup.split("-")[1],
          InvDescription: inventoryState.createDescription,
          InvPart: inventoryState.createPartNum,
          InvCost: inventoryState.createCost,
          InvUnit: inventoryState.createUnit,
          InvQuantity: inventoryState.createQty,
          InvNotes: inventoryState.createNotes,
        },
        headers: {
          Authorization: userAuthObj.idToken,
        },
      });
      console.log("response: ", response);

      if (response.data === null || response.data[0]?.StatusCode === "ERROR") {
        console.log("No results, error, or empty/null response from API.");
        return [];
      } else {
        const id = response.data.InvID;
        let newItemResponse;
        newItemResponse = await axios.get(APIState.InvAPI, {
          params: {
            InvFunc: "GetInvItem",
            InvMode: 1,
            ItemID: id,
            Level1Parm: 0,
            Level2Parm: 0,
            Level3Parm: 0,
            Level4Parm: 0,
            Keywords: "",
          },
          headers: {
            Authorization: userAuthObj.idToken,
          },
        });
        console.log(newItemResponse);
        updateInventory({ createItem: newItemResponse.data[0] });
        return newItemResponse.data[0];
      }
    } catch (e) {
      console.error("GET call failed: ", e);
      return [];
    }
  }

  function handleUpdateInventory(type, value) {
    if (type === "type") {
      updateInventory({
        createType: value,
        createCategory: "",
        createGroup: "",
      });
      // get rows returned from api attached to the json obj w/ parent id
      const results = inventoryState.filters.find(
        (filter) => filter.parentId === value
      )?.relatedRows;
      if (results) {
        updateInventory({ createCategoryOptions: results });
      }
      updateInventory({ createGroupOptions: [] });
    } else if (type === "category") {
      updateInventory({
        createCategory: value,
        createGroup: "",
      });
      const results = inventoryState.filters.find(
        (filter) => filter.parentId === value
      )?.relatedRows;
      if (results) {
        updateInventory({ createGroupOptions: results });
      } else {
        updateInventory({ createGroupOptions: [] });
      }
    } else if (type === "group") {
      updateInventory({
        createGroup: value,
      });
    }
  }

  return (
    <div>
      <Paper
        sx={{
          width: "100%",
          mb: 2,
          padding: "1rem",
          position: "relative",
          borderRadius: "8px",
          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
          textAlign: "center",
        }}
      >
        <CasaCloseButton handleClose={props.backButton} />

        <span
          style={{
            fontSize: "1.5em",
            textDecoration: "underline",
            fontWeight: "bold",
          }}
        >
          Create New {props.name} Item
        </span>
        <br />
        <br />
        <Grid container spacing={2}>
          {/* On smaller screens, display as 4x1 grid */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="description-textfield"
              label="Description"
              value={inventoryState.createDescription}
              onChange={(e) => {
                updateInventory({ createDescription: e.target.value });
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              variant="outlined"
              sx={{
                textAlign: "left",
              }}
            >
              <InputLabel id="type-label">
                {!filtersLoaded ? "Loading..." : "Type"}
              </InputLabel>
              <Select
                fullWidth
                labelId="type-label"
                id="type"
                value={inventoryState.createType}
                onChange={(event) => {
                  handleUpdateInventory("type", event.target.value);
                }}
                label={!filtersLoaded ? "Loading..." : "Type"}
                disabled={!filtersLoaded}
              >
                {inventoryState.createTypeOptions.map((option) => (
                  <MenuItem key={option.LevelID} value={option.LevelID}>
                    {option.LevelDesc}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              variant="outlined"
              sx={{
                textAlign: "left",
              }}
            >
              <InputLabel id="category-label">
                {!filtersLoaded ? "Loading..." : "Category"}
              </InputLabel>
              <Select
                fullWidth
                labelId="category-label"
                id="category"
                value={inventoryState.createCategory}
                onChange={(event) => {
                  handleUpdateInventory("category", event.target.value);
                }}
                label={!filtersLoaded ? "Loading..." : "Category"}
                disabled={
                  inventoryState.createCategoryOptions.length === 0 ||
                  inventoryState.createType === "" ||
                  !filtersLoaded
                }
              >
                {inventoryState.createCategoryOptions.map((option) => (
                  <MenuItem key={option.LevelID} value={option.LevelID}>
                    {option.LevelDesc}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              variant="outlined"
              sx={{
                textAlign: "left",
              }}
            >
              <InputLabel id="group-label">
                {!filtersLoaded ? "Loading..." : "Group"}
              </InputLabel>
              <Select
                fullWidth
                labelId="group-label"
                id="group"
                value={inventoryState.createGroup}
                onChange={(event) => {
                  handleUpdateInventory("group", event.target.value);
                }}
                label={!filtersLoaded ? "Loading..." : "Group"}
                disabled={
                  inventoryState.createGroupOptions.length === 0 ||
                  inventoryState.createCategory === "" ||
                  !filtersLoaded
                }
              >
                {inventoryState.createGroupOptions.map((option) => (
                  <MenuItem key={option.LevelID} value={option.LevelID}>
                    {option.LevelDesc}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="mfg-num-textfield"
              label="Part #"
              value={inventoryState.createPartNum}
              onChange={(e) => {
                updateInventory({ createPartNum: e.target.value });
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="unit-textfield"
              label="Unit"
              value={inventoryState.createUnit}
              onChange={(e) => {
                updateInventory({ createUnit: e.target.value });
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="cost-textfield"
              label="Cost"
              value={inventoryState.createCost}
              onChange={(e) => {
                updateInventory({ createCost: e.target.value });
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="qty-textfield"
              label="Qty"
              value={inventoryState.createQty}
              onChange={(e) => {
                updateInventory({ createQty: e.target.value });
              }}
            />
          </Grid>
          <Grid item xs={24} sm={12}>
            <TextField
              fullWidth
              id="notes-textfield"
              label="Notes"
              value={inventoryState.createNotes}
              onChange={(e) => {
                updateInventory({ createNotes: e.target.value });
              }}
            />
          </Grid>
        </Grid>
        <br />
        <Button variant="contained" color="primary" onClick={handleAdd}>
          Create
        </Button>
      </Paper>
    </div>
  );
};

export default InventoryCreate;
