import React from "react";
import {
  Box,
  FormControlLabel,
  Switch,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
} from "@mui/material";
import { styled } from "@mui/system";
import SupervisorAccount from "@mui/icons-material/SupervisorAccount";
import Chat from "@mui/icons-material/Chat";
import Warehouse from "@mui/icons-material/Warehouse";
import PunchClock from "@mui/icons-material/PunchClock";

const StyledListItem = styled(ListItem)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(2),
  marginBottom: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.frontPaper,
  boxShadow: theme.shadows[1],
  border: `1px solid ${theme.palette.divider}`,
}));

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: "40px",
  paddingRight: "12px",
}));

const Container = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  maxWidth: 500,
  margin: "auto",
  backgroundColor: theme.palette.background.light,
  borderRadius: theme.shape.borderRadius,
}));

const CasaSwitchForm = () => {
  const [permissions, setPermissions] = React.useState({
    administration: true,
    inventory: true,
    messages: true,
    timeclock: true,
  });

  const handleChange = (app) => (event) => {
    setPermissions({ ...permissions, [app]: event.target.checked });
  };

  const apps = [
    {
      id: "administration",
      label: "Administration",
      icon: <SupervisorAccount />,
    },
    { id: "inventory", label: "Inventory", icon: <Warehouse /> },
    { id: "messages", label: "Messages", icon: <Chat /> },
    { id: "timeclock", label: "Time Clock", icon: <PunchClock /> },
  ];

  return (
    <Container elevation={3}>
      <List>
        {apps.map((app) => (
          <StyledListItem key={app.id}>
            <Box display="flex" alignItems="center">
              <StyledListItemIcon>{app.icon}</StyledListItemIcon>
              <ListItemText primary={app.label} />
            </Box>
            <FormControlLabel
              control={
                <Switch
                  checked={permissions[app.id]}
                  onChange={handleChange(app.id)}
                  color="primary"
                />
              }
              label=""
            />
          </StyledListItem>
        ))}
      </List>
    </Container>
  );
};

export default CasaSwitchForm;
