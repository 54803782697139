// CasaSnackbar.js
import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Slide from "@mui/material/Slide";
import Fade from "@mui/material/Fade";

const CasaSnackbar = (props) => {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    props.setOpen(false);
  };

  return (
    <div>
      <Snackbar
        open={props.open}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        TransitionComponent={Slide}
        TransitionProps={{
          direction: "right", // Specify the slide direction
          mountOnEnter: true, // Ensures the component mounts when it enters
          unmountOnExit: true, // Ensures the component unmounts when it exits
        }}
        transitionclosecomponent={Fade} // Specify the closing animation
        transitioncloseprops={{
          timeout: 500, // Set the duration of the fade-out animation
        }}
      >
        <Alert
          onClose={handleClose}
          severity={props.message === "Error." ? "error" : "success"}
          variant="filled"
          sx={{ width: "100%", color: "white" }}
        >
          {props.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CasaSnackbar;
