// Managers.js
import React from "react";
import CasaPlaceholderScreen from "../../Resuable/CasaPlaceholderScreen/CasaPlaceholderScreen";

const Managers = (props) => {
  return (
    <CasaPlaceholderScreen name="Managers" backButton={props.backButton} />
  );
};

export default Managers;
