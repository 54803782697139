// CasaPlaceholderScreen.js
import React from "react";
import Box from "@mui/material/Box";
import { Button, Paper, Typography, useTheme } from "@mui/material";
import CasaCloseButton from "../CasaCloseButton/CasaCloseButton";

const CasaPlaceholderScreen = (props) => {
  const theme = useTheme();

  return (
    <div>
      <Paper
        sx={{
          width: "100%",
          mb: 2,
          position: "relative",
          borderRadius: "8px",
          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
          textAlign: "center",
        }}
      >
        <CasaCloseButton handleClose={props.backButton} />
        <Box
          sx={{
            backgroundColor: theme.palette.customColors.screenHeadingColor, // Update with your desired color
            padding: "10px", // Adjust the padding
            textAlign: "center", // Center-align text
          }}
        >
          <Typography
            variant="h5"
            color="white"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div style={{ flex: 1, textAlign: "center" }}>
              <b>{props.name}</b>
            </div>
          </Typography>
        </Box>
        <Box
          sx={{
            paddingRight: "2em",
            paddingLeft: "2em",
            paddingBottom: "1em",
            paddingTop: "0.5em",
            overflow: "auto",
            textAlign: "center",
          }}
        >
          <br />
          <center>
            <span>{props.name} home will go here.</span>
          </center>

          {props.backButton && (
            <>
              <br />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={props.backButton}
              >
                Back
              </Button>
            </>
          )}
          <br />
        </Box>
      </Paper>
    </div>
  );
};

export default CasaPlaceholderScreen;
