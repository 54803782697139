import React from "react";
import {
  Autocomplete,
  Box,
  Button,
  Paper,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAdministrationContext } from "../../../contexts/AdministrationContext";
import CasaCloseButton from "../CasaCloseButton/CasaCloseButton";

const CasaSearchComponent = (props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  // const [searchParams] = useSearchParams();
  const { administrationState, updateAdministration } =
    useAdministrationContext();

  const handleChange = (event, value) => {
    if (props.type === "employee") {
      updateAdministration({ employeeName: value });
    } else if (props.type === "customer") {
      updateAdministration({ customerName: value });
    }
  };

  const processSearchReq = () => {
    // Update URL params with selected employee name
    navigate(props.onClickURL);
  };

  return (
    <div>
      <Paper
        sx={{
          width: "100%",
          mb: 2,
          position: "relative",
          borderRadius: "8px",
          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
          textAlign: "center",
        }}
      >
        <CasaCloseButton handleClose={props.backButton} />
        <Box
          sx={{
            backgroundColor: theme.palette.customColors.screenHeadingColor,
            padding: "10px",
            textAlign: "center",
          }}
        >
          <Typography
            variant="h5"
            color="white"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div style={{ flex: 1, textAlign: "center" }}>
              <b>{props.title}</b>
            </div>
          </Typography>
        </Box>
        <Box
          sx={{
            paddingRight: "2em",
            paddingLeft: "2em",
            paddingBottom: "1em",
            paddingTop: "0.5em",
            overflow: "auto",
          }}
        >
          <center>
            <div style={{ padding: "20px" }}>
              <Typography
                variant="findSearchSubtitle"
                component="div"
                align="center"
                sx={{
                  fontWeight: "bold",
                  textDecoration: "underline",
                }}
              >
                {props.subtitle}
              </Typography>
              <Autocomplete
                id="search-employees-text"
                freeSolo
                groupBy={(option) => option.substring(0, 1)}
                value={
                  props.type === "employee"
                    ? administrationState.employeeName
                    : administrationState.customerName
                }
                onChange={handleChange}
                options={
                  props.type === "employee"
                    ? administrationState.employeeList
                    : administrationState.customerList
                }
                renderInput={(params) => (
                  <TextField {...params} label={props.placeholder} />
                )}
                style={{
                  marginTop: "10px",
                  width: "70%",
                }}
              />
              <br />
              <Button
                variant="contained"
                color="primary"
                style={{
                  marginTop: "-10px",
                }}
                onClick={processSearchReq}
                disabled={
                  props.type === "employee"
                    ? !administrationState.employeeName
                    : !administrationState.customerName
                }
              >
                {props.buttonText}
              </Button>
            </div>
          </center>
        </Box>
      </Paper>
    </div>
  );
};

export default CasaSearchComponent;
